<template>
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.64192 18.8868L0.494249 24.0345C-0.164761 24.6935 -0.164761 25.7619 0.494249 26.4209C1.15326 27.08 2.22173 27.08 2.88074 26.4209L8.0284 21.2733C10.0004 22.7502 12.4494 23.625 15.1027 23.625C21.6266 23.625 26.9152 18.3364 26.9152 11.8125C26.9152 5.28864 21.6266 0 15.1027 0C8.57883 0 3.29019 5.28864 3.29019 11.8125C3.29019 14.4658 4.165 16.9148 5.64192 18.8868ZM15.1027 20.25C10.4428 20.25 6.66519 16.4724 6.66519 11.8125C6.66519 7.1526 10.4428 3.375 15.1027 3.375C19.7626 3.375 23.5402 7.1526 23.5402 11.8125C23.5402 16.4724 19.7626 20.25 15.1027 20.25Z"
      fill="#9EAFE1"
    />
  </svg>
</template>

<script>
export default {
  name: 'SearchRight',
}
</script>
